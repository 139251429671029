<template>
  <div>
    <dashboard-page-title
      v-if="productItem.length < 0"
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      إضافة منتج
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-file-alt"></i>{{ $t("main.basic_info") }}
              </h5>
            </template>
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.ar.enterName')"
                  :label="$t('main.ar.name')"
                  v-model="product.title_ar"
                  :disabled="routeName === 'showProduct'"
                  validate="required"
                ></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.en.enterName')"
                  :label="$t('main.en.name')"
                  v-model="product.title_en"
                  :disabled="routeName === 'showProduct'"
                  validate="required"
                ></input-form>
              </b-col>

              <b-col md="6" class="mb-3">
                <textarea-form
                  :placeholder="$t('main.ar.description')"
                  v-model="product.description_ar"
                  name="description_ar"
                  :disabled="routeName === 'showProduct'"
                  validate="required"
                  :label="$t('main.ar.description')"
                ></textarea-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <textarea-form
                  :placeholder="$t('main.en.description')"
                  v-model="product.description_en"
                  name="description_en"
                  :disabled="routeName === 'showProduct'"
                  validate="required"
                  :label="$t('main.en.description')"
                ></textarea-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <main-select
                  :label-title="$t('main.chooseStatus')"
                  :placeholder="$t('main.chooseStatus')"
                  v-model="product.status"
                  :options="allStatus"
                  :reduce="(i) => i.value"
                  label="text"
                  :disabled="routeName === 'showProduct'"
                  name="status"
                  validate="required"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-image"></i>
                {{ $t("main.images") }}
              </h5>
            </template>
            <b-row>
              <b-col md="12" class="mb-3">
                <cropper-images
                  :label="$t('main.uploadMainImages')"
                  nameOfImage="image.jpg"
                  @cropper-save="saveImage($event, 'featured_image')"
                  :multi="false"
                  :progressLoading="loadingGallery"
                  :imageUrl="featured_image"
                />
              </b-col>
              <b-col md="12" class="mb-3">
                <cropper-images
                  :label="'الصور الفرعية'"
                  nameOfImage="image.jpg"
                  @cropper-save="saveImage($event, 'images')"
                  :multi="true"
                  :images="images"
                  @remove-image="removeImage"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-box-open"></i>
                {{ $t("products.categories.title") }} {{ $t("main.and") }}
                {{ $t("products.branches.title") }}
              </h5>
            </template>
            <div class="m-0 p-0">
              <b-row>
                <b-col md="6" class="mb-3">
                  <main-select
                    :label-title="$t('products.categories.chooseCategory')"
                    :placeholder="$t('products.categories.chooseCategory')"
                    v-model="product.category_id"
                    :options="allCategories"
                    :reduce="(i) => i.id"
                    label="name"
                    :disabled="routeName === 'showProduct'"
                    name="category_id"
                    validate="required"
                  />
                </b-col>
                <b-col md="6" class="mb-3">
                  <main-select
                    :label-title="$t('products.branches.chooseBranches')"
                    :placeholder="$t('products.branches.chooseBranches')"
                    v-model="product.branches_ids"
                    :options="allBranches"
                    :reduce="(i) => i.id"
                    multiple
                    label="name"
                    :disabled="routeName === 'showProduct'"
                    name="branch_id"
                    validate="required"
                  />
                </b-col>
              </b-row>
            </div>
          </b-card>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-info"></i> {{ $t("products.productDetails") }}
              </h5>
            </template>
            <b-row>
              <b-col md="4" class="mb-3">
                <b-row>
                  <b-col cols="12">
                    <input-form
                      label="السعر"
                      v-model.number="product.net_price"
                      placeholder="ادخل السعر"
                      name="price"
                      :validate="'required|numeric'"
                      :disabled="routeName === 'showProduct'"
                    ></input-form>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="4" class="mb-3">
                <main-select
                  :label-title="$t('products.chooseUnit')"
                  :placeholder="$t('products.chooseUnit')"
                  v-model="product.unit_id"
                  :options="allUnits"
                  :reduce="(i) => i.id"
                  label="name"
                  :disabled="routeName === 'showProduct'"
                  name="weight_unit"
                  validate="required"
                />
              </b-col>
              <b-col md="4" cols="12">
                <input-form
                  label="الوزن"
                  v-model.number="product.weight"
                  placeholder="ادخل الوزن"
                  name="weight"
                  :validate="'required|numeric'"
                  :disabled="routeName === 'showProduct'"
                ></input-form>
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form
                  :type="'number'"
                  :placeholder="product.quantity"
                  label="الكميه المتاحه"
                  v-model.number="product.quantity"
                  name="quantity"
                  :validate="'required'"
                  :disabled="routeName === 'showProduct'"
                ></input-form>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">{{
            $t("main.back")
          }}</b-button>
          <b-button
            v-if="$route.name !== 'showProduct'"
            variant="primary"
            type="submit"
            :disabled="loadingSubmit"
          >
            <span v-if="!loadingSubmit">{{ $t("main.save") }}</span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import productsService from "../services/products";
import CropperImages from "../../../components/cropperImages.vue";
export default {
  components: { CropperImages },
  mounted() {
    this.url_data = this.$route.params.id;
    core.index();
  },
  data() {
    return {
      product: {
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        content: "",
        description: "",
        featured_image: "",
        status: "published",
        category_id: null,
        branches_ids: [],
        quantity: 0,
        price: "",
        unit_id: null,
        sale_price: 0,
        discounts: "",
        length: "0",
        width: "0",
        height: "0",
        weight: null,
        images: [],
        product_categories: [],
        tax_id: "",
        tags: [],
        related_products: [],
        faqs: [],
      },
      featured_image: "",
      images: [],
      faq: { question: "", answer: "" },
      showFaqInput: false,
      selectedProduct: [],
      allProducts: [],
      loadingLogo: 0,
      removeLoadingUi: false,
      loadingGallery: 0,
      allStores: [],
      loadingSubmit: false,
      productItem: {},
      productUpdate: {},
      routeName: this.$route.name,
      allCategories: [],
      allBranches: [],
      allUnits: [],
      allStatus: [
        { text: this.$t("main.status.published"), value: "published" },
        { text: this.$t("main.status.hidden"), value: "hidden" },
      ],
    };
  },
  methods: {
    getAllMerchantProducts() {
      productsService.getAllMerchantProducts().then((res) => {
        this.allProducts = res.data.data;
      });
    },
    getAllUnits() {
      productsService.getAllUnits().then((res) => {
        this.allUnits = res.data.data;
      });
    },
    saveImage(e, key) {
      console.log(e);
      const formData = new FormData();
      formData.append("image", e.image);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.loadingGallery = percent;
        },
      };
      this.commonUploadFiles(
        formData,
        options,
        "merchant/upload/products"
      ).then((res) => {
        if (key === "featured_image") {
          this.product.featured_image = res.data.url;
          this.featured_image = res.data.preview;
        } else {
          this.product.images.push({
            url: res.data.url,
            alt_image: e.imageInfo.name,
          });
          this.images.push({
            url: res.data.preview,
            alt_image: e.imageInfo.name,
          });
        }
        this.showSuccessUploadFile();
        this.loadingGallery = 0;
      });
    },
    removeImage(idx) {
      this.product.images.splice(idx, 1);
      this.images.splice(idx, 1);
    },

    addProduct(productData) {
      this.product.related_products.push({ id: productData.id });
    },

    getProduct() {
      productsService.getProduct(this.$route.params.id).then((res) => {
        this.product = {
          ...res.data.data,
          price: +res.data.data.price,
        };
        this.featured_image = res.data.data.featured_image;
        this.images = [...res.data.data.images];
      });
    },
    editProduct() {
      this.loadingSubmit = true;
      productsService
        .editProduct(this.$route.params.id, {
          ...this.product,
          price: +this.product.net_price,
        })
        .then(() => {
          core.showSnackbar("success", "تم تعديل المنتج بنجاح");
          this.$router.push({ name: "products" });
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
    getAllCategories() {
      productsService.getAllCategories().then((res) => {
        this.allCategories = res.data.data;
      });
    },
    getAllBranches() {
      productsService.getAllBranches().then((res) => {
        this.allBranches = res.data.data;
      });
    },

    onSubmit() {
      if (this.$route.name === "editProduct") {
        this.editProduct();
      } else {
        this.loadingSubmit = true;
        productsService
          .addNewProduct({ ...this.product, price: +this.product.net_price })
          .then(() => {
            core.showSnackbar("success", "تم إضافه المنتج بنجاح");
            this.$router.push({ name: "products" });
            this.loadingSubmit = false;
          })
          .finally(() => {
            this.loadingSubmit = false;
          });
      }
    },
  },
  created() {
    this.getAllCategories();
    this.getAllBranches();
    this.getAllMerchantProducts();
    // this.getAllStores()
    this.getAllUnits();
    if (this.$route.params.id) {
      this.getProduct();
    }
    // this.getProduct()
  },
};
</script>
<style>
.view-main-img {
  width: 120px;
  height: 120px;
}
.image_slider_background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
